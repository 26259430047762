<section class="message">
  <div class="avatar">
    <flame-icon
      class="flame-icon"
      [fontIcon]="messageImg[messageService.message.type]"
    ></flame-icon>
    <div class="badge">
      <flame-icon
        size="xs"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g id="System / Alert">
            <path id="!" d="M10.448 14.8474L10 4.47162C11.1319 4.21223 12.7826 4 13.9381 4L13.4664 14.8474H10.448ZM12.0044 20.9549C10.7782 20.9549 10 20.0824 10 18.9977C10 17.9365 10.7782 17.0404 12.0044 17.0404C13.1835 17.0404 13.9616 17.9365 13.9616 18.9977C13.9616 20.0824 13.1835 20.9549 12.0044 20.9549Z" fill="#EC0000"/>
          </g>
        </svg>
      </flame-icon>
    </div>
  </div>
  <div class="data">
    <p class="title">{{messageService.message.title}}</p>
    <p class="description">{{messageService.message.description}}</p>
  </div>
  <button 
    flameButton 
    color="primary"
    (click)="onRetry()"
  >
    Reintentar
  </button>
  <span class="code">
    {{messageService.message.errorCode}}
  </span>
</section>