import { Injectable, inject } from '@angular/core';
import { GeneralConditionsURL } from '../models/general-conditions-url.model';
import { InitService } from './init.service';

declare const Connect: any;

declare function getOpenInBrowser(params:any):any;

@Injectable({
  providedIn: 'root'
})
export class GeneralConditionsService {

  private initService = inject(InitService);

  public cgUrlMap: any;

  constructor() {
    this.cgUrlMap = this.initService.getUrlVar()    
   }

  async openInBrowser(branchCode: string, productId: string, textCriteria: string) { 
    const webkit = (window as any).webkit;
    let url;
    if (textCriteria === 'F'){
      url = await this.findUrlToAutocompara();
    } else {
      url = await this.findUrl(branchCode, productId);
    }
    console.log(url)
    if (typeof webkit !== "undefined") {      
      const params = {
        "name": "openInBrowser",
        "parameters": {
          "urlString": url
        },
        "callbackName": null
      };
      
      await getOpenInBrowser(JSON.stringify(params));
    }
    else {
      Connect.openInBrowser(url);
    }
  }

  private async findUrl(branchCode: string, productId: string): Promise<string> {    
    return this.cgUrlMap.then((gcu:{document: GeneralConditionsURL[]}) => {
        return gcu.document.filter((gcu : GeneralConditionsURL) => gcu.branchCode === branchCode || gcu.productId.toLowerCase().includes(productId.toLowerCase()))[0]?.url || ''
    })
  }

  private async findUrlToAutocompara(): Promise<string> {    
    return this.cgUrlMap.then((gcu:{document: GeneralConditionsURL[]}) => {
      return gcu.document.filter((gcu : GeneralConditionsURL) => gcu.branchCode === '40')[0]?.url || ''
    })
  }
}
