<section 
  class="accordion"
  [ngClass]="{
    'first': isFirst,
    'last': isLast
  }"
>
  <div 
    class="title"
    (click)="onOpenCollapsible()"
  >
    <div class="left">

      <flame-avatar 
        size="xs"
      >
        <flame-icon 
          class="phone"
          size="xs" 
          [fontIcon]="accordion.icon"
        ></flame-icon>
      </flame-avatar>
      <p class="accordion-name">{{accordion.title}}</p>
    </div>
    <flame-icon 
      fontIcon="{{!isOpen ? 'chevron-down' : 'chevron-up'}}"
      *ngIf="accordion?.isCollapsible"
    ></flame-icon>
  </div>
  <div 
    class="collapsible"
    *ngIf="accordion?.isCollapsible && isOpen"
  >
    <ng-content></ng-content>
  </div>
</section>