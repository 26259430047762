import { Component, Input, inject } from '@angular/core';
import { ReturnTo } from 'src/app/core/enums/return-to.enum';
import { GoToService } from 'src/app/core/services/go-to.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() header: string = '';
  @Input({ required: true }) returnTo!: ReturnTo;

  goToService = inject(GoToService)

  onReturnPage() {
    this.goToService.goToPage(this.returnTo)
  }
}
