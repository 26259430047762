import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FlameBottomSheetService, FlameTemplateModalOverlayRef } from '@ngx-mxflame/atoms/bottom-sheet';
import { ModalConfigInterface } from '@ngx-mxflame/atoms/bottom-sheet/interfaces';
import { MessageService } from './core/services/message.service';
import { MessageComponent } from './modules/shared/components/message/message.component';

import { Subject, Subscription, fromEvent, map, merge, of, takeUntil } from 'rxjs';
import { MessageType } from './core/enums/message-type.enum';
import { LoginService } from './core/services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  
  title = 'Polizas Seguros';
  networkStatus$: Subscription = Subscription.EMPTY;
  ssoToken: string = '';

  notifyOnDestroy$: Subject<void> = new Subject<void>();


  messageService = inject(MessageService);
  loginService = inject(LoginService);
  flameTemplateModalOverlayRef: FlameTemplateModalOverlayRef | undefined;

  constructor(
    private bottomSheetService: FlameBottomSheetService
  ) {}

  ngOnInit(): void {
    this.onCheckNetworkStatus()
    this.handlerShowMessage()
    this.verifySsoToken();
    this.handleCloseModalToRetry()
  } 

  verifySsoToken() {
    const decoded = decodeURIComponent(window.location.search)
    const queryParams = this.getQueryParams(decoded);
    localStorage.setItem('token', queryParams['token']);
    this.loginService.verifySSOToken();
  }

  ngOnDestroy(): void {
    this.notifyOnDestroy$.next();
    this.notifyOnDestroy$.complete();
  }

  private getQueryParams(url: any) {
    const params: any = {};
    const urlParams = new URLSearchParams(url)
    urlParams.forEach((val, key) => {
      params[key] = val.replace(/ /g, '+');
    })
    return params;
  }

  onCheckNetworkStatus() {
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(
      map(() => navigator.onLine),
      takeUntil(this.notifyOnDestroy$)
    ).subscribe(status => {
      
      if (!status) {
        this.messageService.setData({
          type: MessageType.OFFLINE,
          title: '¡Sin conexión a internet!',
          description: 'Comprueba tu conexión o espera unos minutos e inténtalo de nuevo.',
          icon: '',
          errorCode: 0
        })
        this.messageService.showMessage()
      }
    })
  }

  handlerShowMessage() {
    this.messageService
      .isVisible$
      .pipe(
        takeUntil(this.notifyOnDestroy$)
      )
      .subscribe(val => {
        const config: ModalConfigInterface = {
          title: '',
           subtitle: '',
          closeLabel: ''

        };
        if (val) {
          this.flameTemplateModalOverlayRef = 
          this.bottomSheetService.open(
            MessageComponent, config
          )         
        }
      })
  }

  handleCloseModalToRetry() {
    this.messageService
      .onRetry$
      .pipe(
        takeUntil(this.notifyOnDestroy$)
      )
      .subscribe(() => {
        this.flameTemplateModalOverlayRef?.close()
      })
  }
}
