import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { MessageService } from '../services/message.service';
import { MessageType } from '../enums/message-type.enum';
import { RetryAction } from '../enums/retry-action.enum';

@Injectable()
export class ThereWasAnErrorInterceptor implements HttpInterceptor {

  messageService = inject(MessageService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        const errorCode: number = error.status;
        const whatService: string = request.urlWithParams.includes('policy-documents-inquiry') ? 'download' : 'list';
        this.setDataOfMessage(whatService, errorCode)
        this.messageService.showMessage()
        return throwError(() => error);
      })
    );
  }

  private setDataOfMessage(whatService: string, errorCode: number) {
    if (whatService === 'list') {
      this.messageService.setData({
        type: MessageType.CANNOTLOAD,
        icon: '',
        title: 'No es posible consultar tus pólizas',
        description: 'Por favor inténtalo nuevamente.',
        retryAction: RetryAction.LIST,
        errorCode: errorCode
      })
    } else {
      this.messageService.setData({
        type: MessageType.CANNOTDOWNLOAD,
        icon: '',
        title: 'No es posible descargar tu póliza',
        description: 'Por favor inténtalo nuevamente.',
        retryAction: RetryAction.DETAIL,
        errorCode: errorCode
      })
    }
  }
}
