<div class="header">
  <div 
    class="return"
    (click)="onReturnPage()"
  >
    <flame-icon fontIcon="chefron-left" size="m" ></flame-icon>
  </div>

  <ng-template [ngIf]="header === 'AUTOCOMPARA'">
    <p class="title">{{header | capitalize}}</p>
  </ng-template>
  <ng-template [ngIf]="header !== 'AUTOCOMPARA'">
    <p class="title">{{header | capitalize}}</p>
  </ng-template>

</div>