import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { AccordionContent } from 'src/app/core/models/accordion-content.model';
import { AccordionItem } from 'src/app/core/models/accordion-item.model';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnChanges, AfterViewInit{

  @Input({required: true}) isFirst!: boolean;
  @Input({required: true}) isLast!: boolean;
  @Input({required: true}) accordion: AccordionItem = {
    icon: '',
    title: '',
    isCollapsible: false
  };
  @Input() content: AccordionContent | null = null;
  @Input() closeAccordion: boolean = false;

  @Output() openCollapsible: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() accordionClicked: EventEmitter<{title: string, opened: boolean}> = new EventEmitter<{title: string, opened: boolean}>();

  isOpen: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['closeAccordion'].currentValue) {
      this.isOpen = false;
    }
  }

  ngAfterViewInit(): void {
    document.querySelectorAll('.fl-avatar__circle').forEach((el) => {
      if(el instanceof HTMLElement) {
        el.style.backgroundColor = '#F2F2F2'
      }
    });
  }

  onOpenCollapsible() {
      this.isOpen = !this.isOpen;
      this.openCollapsible.emit(this.isOpen);
      this.accordionClicked.emit({ title: this.accordion.title, opened: this.isOpen });
  }
}
