import { Injectable, inject } from "@angular/core";
import { ReturnTo } from "../enums/return-to.enum";
import { Router } from "@angular/router";

declare const Connect: any;

@Injectable({
  providedIn: 'root'
})
export class GoToService {

  goToPage(returnTo: ReturnTo) {
    if (returnTo === ReturnTo.backToDashboard) {
      console.log('regresa al dashboard');
      history.back();
    }
    if (returnTo === ReturnTo.backToApp) {
      console.log('se va a la app');
      this.goToRoot();
    }
  }

  goToRoot() {
    try {
      const params = {
        name: 'goToRoot',
        parameters: null,
        callbackName: null
      };
      const webkit = (window as any).webkit;
      if (typeof webkit !== 'undefined') {
        webkit.messageHandlers.Connect.postMessage(JSON.stringify(params));
      } else {
        Connect.goToRoot()
      }
    } catch(_err) {
      console.log(_err);
      
    }
  }

}