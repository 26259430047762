import { Component, Input } from '@angular/core';
import { ClarificationsAndCancellationsItem } from 'src/app/core/models/clarification-and-cancellation-item.model';

@Component({
  selector: 'app-clarifications-and-cancellations-item',
  templateUrl: './clarifications-and-cancellations-item.component.html',
  styleUrls: ['./clarifications-and-cancellations-item.component.scss']
})
export class ClarificationsAndCancellationsItemComponent {
  @Input({required: true})
  item!: ClarificationsAndCancellationsItem

}
