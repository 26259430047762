import { AfterViewInit, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MESSAGE_IMG } from '../../../../core/consts/message-icon.const';
import { MessageType } from '../../../../core/enums/message-type.enum';
import { MessageService } from '../../../../core/services/message.service';
import { TaggingService } from 'src/app/core/services/tagging.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, AfterViewInit, OnDestroy {

  messageImg = MESSAGE_IMG;
  messageService = inject(MessageService);
  taggingService = inject(TaggingService);
  route = inject(ActivatedRoute)
  messageType = MessageType;

  ngOnInit(): void {
    const {policyNumber} = this.route.snapshot.queryParams;      
      let action = "mis_seguros/";

    if (typeof policyNumber !== 'undefined') {
      action = "mis_seguros/detalle/"
    }
    this.taggingService.setTagAction(
      `${this.messageService.message.title} ${this.messageService.message.description}`,
      undefined,
      action,
      'error'
      )
  }

  ngAfterViewInit(): void {
    this.setDisplay('block')
  }

  ngOnDestroy(): void {
    this.setDisplay('flex')
  }

  onRetry() {
    console.log('==> Retry', this.messageService.message.retryAction);
    this.messageService.onRetry()
  }

  private setDisplay(type: string) {
    const myDiv = document.querySelector('.fl-bottom-sheet-header-content');
    if(myDiv) {
      (myDiv as HTMLElement).style.display = type
    }
  }

}
