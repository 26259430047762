import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from "./../../../environments/environment";
import { GeneralConditionsURL } from '../models/general-conditions-url.model';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  constructor(public http: HttpClient) {}
  
  public async getUrlVar(): Promise<{document: GeneralConditionsURL[]}> {
    return await firstValueFrom(this.http.get<{document: GeneralConditionsURL[]}>(environment.urlGeneralConditions));
  }
}
