import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MessageType } from '../enums/message-type.enum';
import { Message } from '../models/message.model';
@Injectable({
  providedIn: 'root'
})
export class MessageService {

  isVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onRetry$: Subject<void> = new Subject();
  
  message: Message = {
    type: MessageType.SUCCESS,
    icon: '',
    title: '',
    description: '',
    errorCode: 0
  }

  showMessage(): void {
    this.isVisible$.next(true);
  }

  hiddeMessage(): void {
    this.isVisible$.next(false)
  }

  setData(message: Message): void {
    this.message = message;
  }

  onRetry() {
    this.onRetry$.next();
  }

  clearData(): void {
    this.message = {
      type: MessageType.SUCCESS,
      icon: '',
      title: '',
      description: '',
      errorCode: 0
    }
  }

}
