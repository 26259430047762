<section class="sheet">
      <div class="slot">
        <div class="logos">
          <img class="vector" src="assets/images/icon-santander.png"
          alt="iconSantaner"/>
        </div>
        <p class="label">
          <span class="text-wrapper">Si tienes alguna duda, <strong> revisa los detalles de tu seguro </strong> </span>
        </p>
        <p 
          class="div"
          *ngIf="subFamilyCode !== 'AUTOCOMPARA'"
        >
        Por tu seguridad, cerraremos tu sesión en SuperMóvil para dirigirte a Zurich Santander, un sitio externo a Santander, el cual mantiene sus propias políticas de seguridad y privacidad.
      </p>
        <p 
          class="div"
          *ngIf="subFamilyCode === 'AUTOCOMPARA'"
        >
        Por tu seguridad, cerraremos tu sesión en SuperMóvil para dirigirte a Autocompara, el cual mantiene sus propias políticas de seguridad y privacidad.
        </p>
          <button flameButton color="primary" (click)="onRedirect()">Revisar detalles</button>
      </div>
</section>