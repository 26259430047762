import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlameTheme, FlameThemeModule, FlameThemeService } from '@ngx-mxflame/atoms/theme';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-MX';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FlameBottomSheetModule } from '@ngx-mxflame/atoms/bottom-sheet';
import { HeadersInterceptor } from './core/interceptors/headers.interceptor';
import { ThereWasAnErrorInterceptor } from './core/interceptors/there-was-an-error.interceptor';
import { SharedModule } from './modules/shared/shared.module';


registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FlameThemeModule.forRoot({
      themes: [FlameTheme],
      default: FlameTheme
    }),
    BrowserAnimationsModule,
    FlameBottomSheetModule,
    
  ],
  providers: [
    FlameThemeService,
    {
      provide: LOCALE_ID,
      useValue: 'es'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ThereWasAnErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
