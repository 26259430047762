import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { alreadyHaveTokenGuard } from './core/guards/already-have-token.guard';

const routes: Routes = [  
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'insurance'
  },
  { 
    path: 'insurance', 
    loadChildren: () => import('./modules/insurance/insurance.module').then(m => m.InsuranceModule),
    canLoad: [alreadyHaveTokenGuard]
  },
  { 
    path: 'insurance/:id', 
    loadChildren: () => import('./modules/insurance-detail/insurance-detail.module').then(m => m.InsuranceDetailModule) 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
