import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ClarificationCancellationType } from 'src/app/core/enums/clarification-cancellation-type.enum';
import { ClarificationsAndCancellationsItem } from 'src/app/core/models/clarification-and-cancellation-item.model';

@Component({
  selector: 'app-clarifications-and-cancellations',
  templateUrl: './clarifications-and-cancellations.component.html',
  styleUrls: ['./clarifications-and-cancellations.component.scss']
})
export class ClarificationsAndCancellationsComponent implements OnChanges {
  @Input({required: true})
  isZurich!: boolean;

  itemsClarifications: ClarificationsAndCancellationsItem[] = []
  itemsCancellations: ClarificationsAndCancellationsItem[] = []
  ClarificationCancellationType = ClarificationCancellationType

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isZurich']) {
      this.generateItems();
    }
  }

  generateItems() {
    if (this.isZurich) {
      this.itemsClarifications = [
        {
          icon: 'email',
          title: 'Envíanos un correo a',
          value: 'ueaseguros@santander.com.mx',
          type: ClarificationCancellationType.CLARIFICATION
        },
        {
          icon: 'phone',
          title: 'Llámanos al 55 1037 3500',
          value: 'Ext. 13597 o 13599',
          type: ClarificationCancellationType.CLARIFICATION
        },
        {
          icon: 'location',
          title: 'Visítanos en',
          value: 'Juan Salvador Agraz No. 73 int piso 3, Colonia Santa Fe Cuajimalpa C.P. 5348, Alcaldía Cuajimalpa de Morelos, CDMX.',
          type: ClarificationCancellationType.CLARIFICATION
        }
      ];
      this.itemsCancellations = [
        {
          icon: 'phone',
          title: 'Llámanos al 55 5169 4300',
          value: 'Opción Seguros',
          type: ClarificationCancellationType.CANCELLATION
        }
      ]
    } else {
      this.itemsClarifications = [
        {
          icon: 'phone',
          title: 'Llámanos al 55 5169 4308',
          type: ClarificationCancellationType.CLARIFICATION
        }
      ]
    }
  }
}
