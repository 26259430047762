import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Subject, take } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private httpClient = inject(HttpClient);

  alreadyHaveToken$: Subject<string> = new Subject<string>()


  clearToken() {
    localStorage.removeItem('token')
  }

  verifySSOToken() {
    this.httpClient
    .post(
      environment.urlVerifySSO,
      {
        authCode: localStorage.getItem('token'), 
        expires_in: 2000
      }
    )
    .pipe(take(1))
    .subscribe((data: any) => {
      this.alreadyHaveToken$.next(localStorage.getItem('token') || '');
    })
  }

}
