import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string): string {

    if (!value) return value;
    
    return value.split(' ').map(world => world.charAt(0).toUpperCase() + world.slice(1).toLowerCase()).join(' ');
  }

}
