import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { map } from 'rxjs';
import { LoginService } from '../services/login.service';

export const alreadyHaveTokenGuard: CanActivateFn = (route, state) => {
  const loginService = inject(LoginService);
  return loginService.alreadyHaveToken$.pipe(
    map((data) => {
      return data.length > 0;
    }))
};
