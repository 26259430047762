import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { delay, finalize, Observable, of, switchMap, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { LoaderService } from "../services/loader.service";

@Injectable({
  providedIn: "root"
})
export class HeadersInterceptor implements HttpInterceptor {
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let loaderService = inject(LoaderService);
    loaderService.showLoader();
    const start = Date.now();
    let clonedRequest;
    if(req.url.includes('token')) {
      clonedRequest = this.setHeaderSecurity(req, false)
    } else {
      clonedRequest = this.setHeaderSecurity(req, true)
    }
    return next.handle(clonedRequest)
    .pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const duration = Date.now() - start;
          const remaningTime = Math.max(0, 2000, - duration);
          return of(event).pipe(delay(remaningTime))
        }
        return event;
      }),
      switchMap(event => of(event)),
      finalize(() => {loaderService.hideLoader()})
    )
  }

  private setHeaderSecurity(req: HttpRequest<any>, hasSession: boolean): HttpRequest<any> {
    let clonedRequest
    if (hasSession) {
      clonedRequest = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '\'*\'',
          'Content-type': 'application/json; charset=UTF-8',
        }
      });
    } else {
      clonedRequest = req.clone({
        setHeaders: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      })
    }
    return clonedRequest;
  }
}