import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

declare let dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class TaggingService {

  setTagPage(pageName: string, url: string, product: string) {
    // TODO: cual es el userId? de donde lo tomo
    const userId: string = environment.clientId;
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        event: 'pageview',
        url: url.toLowerCase().replace(/ /g, '-'),
        titulo: pageName.toLowerCase().replace(/ /g, '-'),
        tipoSitio: "privado",
        idiomaPagina: "español",
        canalBanco: "supermovil",
        userID: userId,
        ubicacionGeo: "",
        procedencia: "supermovil",
        producto: "seguro-" + product.toLowerCase().replace(/ /g, '-'),
        entorno: environment.env_var,
        tipoDispositivo: "",
        marcaDispositivo: "",
      });
    }
  }

  setTagAction(
    event: string,
    product?: string, 
    action = 'mis-seguros',
    category = 'seguros'
  ) {
    let itemTag: any = {
      event: 'supermovil',
      categoria : category.toLowerCase().replace(/ /g, '-'),
      accion : action.toLowerCase().replace(/ /g, '-'),
      etiqueta : event.toLowerCase().replace(/ /g, '-'),
    }
    if (product) {
      itemTag['producto'] = "seguro-" + product.toLowerCase().replace(/ /g, '-')
    }
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push(itemTag);
    }
  }

}