import { AfterViewInit, Component, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MESSAGE_IMG } from '../../../../core/consts/message-icon.const';
import { MessageType } from 'src/app/core/enums/message-type.enum';
import { MessageService } from 'src/app/core/services/message.service';
import { GeneralConditionsService } from 'src/app/core/services/general-conditions.service';
import { TaggingService } from 'src/app/core/services/tagging.service';

@Component({
  selector: 'app-general-conditions',
  templateUrl: './general-conditions.component.html',
  styleUrls: ['./general-conditions.component.scss']
})
export class GeneralConditionsComponent implements AfterViewInit, OnDestroy {

  messageImg = MESSAGE_IMG;
  messageType = MessageType;
  subFamilyCode: string = '';
  branchCode: string = '';
  textCriteria: string = '';
  productId: string = '';

  messageService = inject(MessageService)
  private route = inject(ActivatedRoute)
  private generalConditionsService = inject(GeneralConditionsService);
  taggingService = inject(TaggingService);

  constructor() {
    this.subFamilyCode = this.route.snapshot.queryParams['subfamilyCode']
    this.branchCode = this.route.snapshot.queryParams['branchCode']
    this.textCriteria = this.route.snapshot.queryParams['textCriteria']
    this.productId = this.route.snapshot.queryParams['productId']

    this.taggingService.setTagPage(
      'mis_seguros|condiciones-generales',
      'mis_seguros/condiciones-generales',
      this.subFamilyCode
     )
  }
  
  ngAfterViewInit(): void {
    this.setDisplay('block')
  }

  ngOnDestroy(): void {
    this.setDisplay('flex')
  }

  onRedirect() {
    this.taggingService.setTagAction(
      'ver-condiciones-generales',
      this.subFamilyCode,
      'condiciones-generales',
    );
    this.generalConditionsService
      .openInBrowser(this.branchCode, this.productId, this.textCriteria);
  }

  private setDisplay(type: string) {
    const myDiv = document.querySelector('.sheet');    
    if(myDiv) {
      (myDiv as HTMLElement).style.display = type
    }
  }

}
