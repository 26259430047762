import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  
  showLoader() {
    this.loader$.next(true)
    console.log('is visible');
    
  }

  hideLoader() {
    this.loader$.next(false)
    console.log('is hiden');
  
  }
}