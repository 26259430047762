import { AccordionValueType } from './../../../../core/enums/accordion-value-type.enum';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccordionItem } from 'src/app/core/models/accordion-item.model';

@Component({
  selector: 'app-accordion-list',
  templateUrl: './accordion-list.component.html',
  styleUrls: ['./accordion-list.component.scss']
})
export class AccordionListComponent {

  @Input({ required: true }) accordionItems!: Array<AccordionItem>;
  @Output() accordionClicked: EventEmitter<{title: string, opened: boolean}> = new EventEmitter<{title: string, opened: boolean}>();
  @Input({required: true})
  isZurich!: boolean;

  accordionOpened: number | null = null;

  accordionValueType = AccordionValueType

  onOpenThis(accordionIndex: number) {
    this.accordionOpened = accordionIndex;
  }

  onAccordionClicked($event: {title: string, opened: boolean}) {
    this.accordionClicked.emit($event)
  }

}
