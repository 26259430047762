import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionListComponent } from './components/accordion-list/accordion-list.component';
import { FlameIconModule } from '@ngx-mxflame/atoms/icon';
import { MessageComponent } from './components/message/message.component';
import { FlameButtonModule } from '@ngx-mxflame/atoms/button';
import { FlameEmptyStateModule } from '@ngx-mxflame/atoms/empty-state'
import { GeneralConditionsComponent } from './components/general-conditions/general-conditions.component';
import { FlameAvatarModule } from '@ngx-mxflame/atoms/avatar';
import { CapitalizePipe } from 'src/app/core/pipes/capitalize.pipe';
import { HeaderComponent } from './components/header/header.component';
import { ClarificationsAndCancellationsComponent } from './components/clarifications-and-cancellations/clarifications-and-cancellations.component';
import { ClarificationsAndCancellationsItemComponent } from './components/clarifications-and-cancellations-item/clarifications-and-cancellations-item.component';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  declarations: [
    AccordionComponent,
    AccordionListComponent,
    MessageComponent,
    GeneralConditionsComponent,
    CapitalizePipe,
    HeaderComponent,
    ClarificationsAndCancellationsComponent,
    ClarificationsAndCancellationsItemComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    FlameIconModule,
    FlameButtonModule,
    FlameEmptyStateModule,
    FlameAvatarModule
  ],
  exports: [
    AccordionListComponent,
    MessageComponent,
    CapitalizePipe,
    HeaderComponent,
    LoaderComponent
  ]
})
export class SharedModule { }
