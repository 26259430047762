export const environment = {
  production: false,
  urlApi: '/policies',
  urlGeneralConditions: '/config.json',
  urlVerifySSO: '/verify-sso',
  grantType: 'client_credentials',
  clientId: 'QnorBRpRQBSPjr2EwWmv6oI3iS-PCU4urzYJdEbRJxw',
  scope: 'policiesinquiry_2.2.0 policy-documents-inquiry_1.1.0',
  clientSt: 'CG0dBXzXk9qHjpGBR8epjNPBO5DXlzr_xaE1aEe-ByI',
  env_var: 'dev'
};
