<section class="accordion-list">
  <app-accordion 
    *ngFor="let accordion of accordionItems; let i = index"
    [isFirst]="i === 0"
    [isLast]="i === accordionItems.length - 1"
    [accordion]="accordion"
    [closeAccordion]="accordionOpened !== i"
    (openCollapsible)="onOpenThis(i)"
    (accordionClicked)="onAccordionClicked($event)"
  >
  <div class="accordion-content">
    <div *ngIf="accordion.title === 'Aclaraciones y Cancelaciones'; else otherAccordion">
      <app-clarifications-and-cancellations
        [isZurich]="isZurich"
      ></app-clarifications-and-cancellations>
    </div>
    <ng-template #otherAccordion>
      <div class="titles">
        <p *ngFor="let str of accordion.content?.title">{{ str }}</p>
      </div>
      <div class="data">
        <div 
          class="row"
          *ngFor="let row of accordion.content?.rows"
        >
          <p
            *ngIf="row?.type === accordionValueType.NAME"
          >
            {{ row.label | capitalize }}
          </p>
          <p
            *ngIf="row?.type !== accordionValueType.NAME"
          >
            {{ row.label }}
          </p>
          <p *ngIf="row?.type === accordionValueType.CURRENCY">
            {{row.value | currency:'MXN'}}
          </p>
          <p *ngIf="row?.type !== accordionValueType.CURRENCY">
            {{row.value | capitalize}}
          </p>
        </div>
      </div>
    </ng-template>
  </div>
</app-accordion>
</section>