<div class="clarifications">
  <span 
    *ngIf="isZurich"
    class="description"
  >
    Para consultas, reclamaciones y aclaraciones sobre tu seguro o asistencia, <strong>visítanos o contáctanos</strong> desde tu canal de preferencia.
  </span>
  <span 
    *ngIf="!isZurich"
    class="description"
  >
    Para consultas, reclamaciones, aclaraciones y cancelaciones sobre tu seguro o asistencia contáctanos.
  </span>
  <app-clarifications-and-cancellations-item
    *ngFor="let item of itemsClarifications"
    [item]="item"
  ></app-clarifications-and-cancellations-item>
  <div>
    <span 
      class="title"
      *ngIf="isZurich"
    >
      Para cancelar tu seguro o asistencia:
    </span>
  </div>
  <app-clarifications-and-cancellations-item
    *ngFor="let item of itemsCancellations"
    [item]="item"
  ></app-clarifications-and-cancellations-item>
</div>